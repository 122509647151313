import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import MasterService from "@/core/services/master.service";

export default {
  components: {
    // Dropdown1
  },

  computed: {
    ...mapGetters([
      "currentUser",
      "currentYear"
    ])
  },

  data() {
    return {
      // :core
      dataUser: {},
      searchMain: {},
      // :end core

      // :last 10 transaction
      tContents: [],
      tContentsTotal: 1,
      tContentsHeader: [
        { text: 'Jenis Pengiriman', align: 'start', value: 'jenis_pengiriman_name', sortable: false},
        { text: 'Satuan', align: 'start', value: 'name', sortable: false},
        { text: 'Actions', align: 'end', value: 'actions', sortable: false},
        
      ],

      tContentsOptions: {},
      tContentsLoading: false,
      tContentsDisabled: false,
      tContentsPaginations: [5,10,15,25,50,100],
      tContentsSearch: {},
      // :end last 10 transaction

      // :additional
      title: "Satuan",
      title_form: "",
      dialogDetail: false,
      formDetail: {},
      dialog: false,
      formValid: false,
      form: {},
      
      loadingJenisPengiriman: false,
      dataJenisPengiriman: [],
      // :end additional

      // :validation
      kodeValidation: [v => ( v && v.length > 9 ) || "This field must have 10 characters", v => !!v || 'This field is required'],
      commonValidation: [v => !!v || 'This field is required'],
      // :end validation
    };
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Master", route: "/" },
      { title: "Jenis Pengiriman" }
    ]),

    this.dataUser = this.currentUser;
    this.searchMain.tahun = this.currentYear;
    this.searchMain.admin_id = Number(this.currentUser.id);
    this.searchMain.branch_id = Number(this.currentUser.branch_id);
  
    this.loadMainContent();
    this.loadJenisPengiriman();
  },

  watch: {
    tContentsOptions: {
      handler () {
        this.loadMainContent();
      },
      deep: true,
    },
  },

  methods: {
    getMainList() {
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.tContentsOptions;
        
        let dataSort = {};
        let isSort = sortDesc.length;
        if(isSort > 0){
          for(let i=0; i<isSort; i++){
            let item1 = sortBy[i];
            let item2 = sortDesc[i];
            
            if(item2 == true){
              dataSort[item1] = "DESC";
            }else{
              dataSort[item1] = "ASC";
            } 
          }
        }

        dataSort["id"] = "DESC";

        var query = { 
          start: (page - 1) * itemsPerPage,
          length: itemsPerPage,
          main: this.searchMain,
          search: this.tContentsSearch
        }

        // ApiService.setHeader();
        ApiService.post("jenis_pengiriman_detail/list", query, 1).then(({ data }) => {
          resolve(data)
        }).catch(({ response }) => {
          reject(response);
        })

      })
    },

    submitSearchTContents(){
      this.tContentsOptions.page = 1;
      this.loadMainContent();
    },

    showFormDialog(content){
      if(this.$refs.vForm) this.$refs.vForm.reset()
      
      setTimeout(() => {
        this.form = {};
        this.title_form = "Tambah";
        if(content){
          this.title_form = "Edit";
          this.form.id = content.id;
          this.form.name = content.name;
          this.form.jenis_pengiriman_id = parseInt(content.jenis_pengiriman_id);
        }
        this.dialog = true;
      }, 50);
    },

    showFormDialogDetail(content){
      setTimeout(() => {
        this.formDetail = {};
        this.formDetail.id = content.id;
        this.formDetail.nama = content.nama;
        this.formDetail.kode = content.kode;
        this.dialogDetail = true;
      }, 50);
    },

    submitForm(){
      if(!this.$refs.vForm.validate()) return;

      var msg = "Simpan";
      if(this.form.id) msg = "Ubah";

      this.$swal({
        title: 'Peringatan',
        html: 'Yakin ingin <strong>'+msg+'</strong> data ini ?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: "Submit"
      }).then((result) => {
        if(result.isConfirmed){

          var query = {
            main: this.searchMain,
            data: this.form
          }
          
          var url = "jenis_pengiriman_detail/create";
          if(this.form.id){
            url = "jenis_pengiriman_detail/update";
          }
    
          this.tContentsLoading = true;
          ApiService.post(url, query, 1).then(({data}) => {
            this.tContentsLoading = false;
            this.dialog = false;
            this.$swal("Success", data.message, "success");
            this.loadMainContent();
          }).catch(({response}) => {
            this.tContentsLoading = false;
            this.$swal("Failed", this.$helpers.getTextAlert(response), "error");
          })

          
        }
      })
    },


    submitDelete(val){
        this.$swal({
          title: 'Peringatan',
          html: 'Yakin ingin hapus data ini ?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: "Submit"
        }).then((result) => {
          if(result.isConfirmed){
            var query = {
              main: this.searchMain,
              data: {
                id: val
              }
            }

            this.tContentsLoading = true;
            ApiService.post("jenis_pengiriman_detail/delete", query, 1).then(({data}) => {
              this.tContentsLoading = false;
              this.$swal("Success", data.message, "success");
              this.loadMainContent();
            }).catch((response)=>{
              this.tContentsLoading = false;
              this.$swal("Failed", this.$helpers.getTextAlert(response), "error");
            })
          }
        })
    },
    loadJenisPengiriman(){
      this.loadingJenisPengiriman = true;
      MasterService.loadMaster("dropdown", "jenis_pengiriman").then(({ data })=>{
        this.loadingJenisPengiriman = false;
        this.dataJenisPengiriman = data.data;
      }).catch((response)=>{
        this.loadingJenisPengiriman = false;
        this.$swal("Failed", this.$helpers.getTextAlert(response), "error");
      });
    },

    // :loader
    loadMainContent(){
      this.tContents = [];
      this.tContentsTotal = 0;
      this.tContentsLoading = true;
      this.getMainList().then(data => {
        this.tContentsLoading = false;
        if(data.detail){
          this.tContents = data.detail
        }else{
          this.tContents = []
        }
        this.tContentsTotal = data.count
      }).catch((response)=>{
        this.tContents = []
        this.tContentsTotal = 0;
        this.tContentsLoading = false;
        this.$swal("Failed", this.$helpers.getTextAlert(response), "error");
      })
    },
    
    
    // :end loader
  },
  
};